<template>
  <div class="error_wrap">
    <div class="container_wrap">
      <div class="left">
        <img :src="require('assets/img/error/error_404.png')" v-if="errorCode === '404'" />
        <img :src="require('assets/img/error/error_403.png')" v-else-if="errorCode === '403'" />
        <img :src="require('assets/img/error/error_500.png')" v-else-if="errorCode === '500'" />
        <img :src="require('assets/img/error/error_404.png')" v-else />
      </div>
      <div class="right">
        <div v-if="errorCode === '404' || !errorCode">
          <h1>404</h1>
          <p>抱歉，您访问的页面不存在</p>
          <el-button type="primary" @click="goPath('/home')">返回首页</el-button>
        </div>
        <div v-else-if="errorCode === '403'">
          <h1>403</h1>
          <p>抱歉，您无权访问该页面</p>
          <el-button type="primary" @click="goPath('/home')">返回首页</el-button>
        </div>
        <div v-else-if="errorCode === '500'">
          <h1>500</h1>
          <p>抱歉，服务器出错了</p>
          <el-button type="primary" @click="goPath('/home')">返回首页</el-button>
        </div>
        <div v-else>
          <h1>404</h1>
          <p>抱歉，您访问的页面不存在</p>
          <el-button type="primary" @click="goPath('/home')">返回首页</el-button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  export default {
    data() {
      return {
        errorCode: this.$route.params.code
      }
    },
    mounted() {
    },
    methods: {
      goPath(path) {
        this.$router.push(path);
      },
    }
  }
</script>

<style lang="scss" scoped>
  .error_wrap {
    background:#f9f9f9;
    position:absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    .container_wrap {
      display: flex;
      align-items: center;
      height: 100%;
      .left {
        width:530px;
        margin-right:100px;
        margin-top:-100px;
        > img {
          width:100%;
        }
      }
      .right {
        padding-top:50px;
        margin-top:-100px;
        flex:1;
        h1 {
          font-size:68px;
          color:#333;
          margin-top:0;
          margin-bottom:50px;
        }
        p {
          font-size:26px;
          color:#666;
          margin-bottom:35px;
          margin-top:0;
        }
      }
    }
  }

</style>
